<template>
  <div class="col-xs-12 col-md-12">
    <b-overlay
      id="overlay-background"
      :show="!hasLoaded"
      fixed
      no-wrap
      variant="light"
      opacity="0.85"
      blur="5px"
    >
    </b-overlay>
    <div v-if="hasLoaded">
      <div class="row">
        <h2>Elementi caricati, cliente {{ get_uploader }}</h2>
      </div>
      <div class="col-xs-12 col-xl-12 col-lg-12">
        <card title="Elementi caricati" :subTitle="'Elementi caricati da ' + get_uploader">
          <vue-tabs>
            <v-tab title="Account" icon="nc-icon nc-circle-09">
              <!-- <div class="col-xs-12 col-md-12">
                <p class="col-xs-6 col-md-6">
                  <b>Utente: </b>
                </p>
                <p class="col-xs-6 col-md-6">
                  {{ reminder.company.first_name + " " + reminder.company.last_name }}
                </p>
              </div>
              <div class="col-xs-12 col-md-12">
                <p class="col-xs-6 col-md-6">
                  <b>Email: </b>
                </p>
                <p class="col-xs-6 col-md-6">
                  {{ reminder.company.metas.contatti ? reminder.company.metas.contatti.email : 'Nessuna email' }}
                </p>
              </div>
              <div class="col-xs-12 col-md-12">
                <p class="col-xs-6 col-md-6">
                  <b>Ragione Sociale: </b>
                </p>
                <p class="col-xs-6 col-md-6">
                  {{ reminder.company.ragione_sociale }}
                </p>
              </div> -->
              <p><b>Cliente: </b>{{ reminder.company.first_name + " " + reminder.company.last_name }}</p>
              <p><b>Email: </b>{{ reminder.company.metas.contatti ? reminder.company.metas.contatti.email : 'Nessuna email' }}</p>
              <p v-if="reminder.company.ragione_sociale"><b>Ragione Sociale: </b>{{ reminder.company.ragione_sociale }}</p>
            </v-tab>
            <v-tab v-for="(el, index) in computed_reminder" :key="index" :title="el.name" icon="nc-icon nc-single-copy-04">
              <div v-if="Array.isArray(el.content)">
                <div v-for="(image, indx) in el.content" :key="indx" class="text-center">
                  <h4>File {{ indx+1 }}</h4>
                  <!-- <img :src="config.backend_url+image" :alt="el.name" style="width: 80%;"> -->
                  <span v-if="el.content[0] == 'non rilevante'">impostato come {{el.content[0]}}</span>
                  <a v-if="el.content[0] !== 'non rilevante'" :href="config.backend_url+image" target="_blank">Clicca qui per: <b>{{ el.name }}</b></a>
                </div>
              </div>
              <div v-else>
                <p>{{ el.content ? el.content : 'Item non ancora caricato!' }}</p>
              </div>
              
            </v-tab>
            <!-- <v-tab title="Info" icon="nc-icon nc-single-copy-04">
              <p>Agency is a group of professional individuals looking to create amazing pieces of clothing. We have studied at the best schools of design, we have tailored the suits for the most stylish men in the industry, we are what you need!</p>
            </v-tab>
            <v-tab title="Style" icon="nc-icon nc-ruler-pencil">
              <p>Explore a wide variety of styles, personalise your finishes, and let us design the perfect home for you. It's what we do best and you can see proof in the products and reviews below.</p>
            </v-tab>
            <v-tab title="Settings" icon="nc-icon nc-settings-90">
              Explore a wide Houses Inc., a group of architects and interior designers based in Chicago and operating for clients worldwide. We’ve been designing stunningly beautiful houses and making clients happy for years.
            </v-tab> -->
          </vue-tabs>
        </card>
      </div>
    </div>
  </div>
  
</template>

<script>
import Vue from 'vue'
import ReminderService from 'src/services/ReminderService'
import { Collapse, CollapseItem } from 'element-ui'
import VueTabs from 'vue-nav-tabs'
import config from 'src/config'

Vue.use(VueTabs)
export default {
  components: {
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem
  },
  data() {
    return {
      hasLoaded: false,
      error: null,
      reminder: null,
      activeName: 'first',
      config: null
    }
  },
  created() {
    this.config = config
  },
  computed: {
    computed_reminder: function () {
      let arr = []
      Object.keys(this.reminder.data).forEach(el => {
        
        arr.push({
          name: this.reminder.template_in_progress.items.filter(el2 => el2.order.toString() === el)[0].label,
          content: this.reminder.data[el],
        })
      })
      console.log('arr',arr)
      return arr
    },
    get_uploader: function() {
      return this.reminder.company.ragione_sociale ? this.reminder.company.ragione_sociale : this.reminder.company.first_name + ' ' + this.reminder.company.last_name
    }
  },
  methods: {
    renderContent(item) {
      if (item.includes('uploads/')) {
        let img = require('assets/img/logo.png')
        // return <img src="assets/img/logo.png"/>
        return item
      } else
        return item
        
    }
  },
  async mounted() {
    try {
      let id = this.$route.params.id
      const ris = await ReminderService.getReminder(id)
      if (ris) {
        console.log('ris', ris)
        this.reminder =  ris.data
      }
    } catch(err) {
      console.log('err',err)
      this.error = 'Errore durante il caricamento degli elementi'
    } finally {
      this.hasLoaded = true
    }
  }
}
</script>

<style lang="scss">
  // css transition for tabs
  .vue-tabs .tab-content {
    padding-top: 10px;
    min-height: 100px;
    display: flex; // to avoid horizontal scroll when animating
    .tab-container {
      animation: fadeIn 0.5s;
    }
  }
</style>